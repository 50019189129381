import { hostname } from "os";

export const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;
export const CLS_BASE_API_URL = process.env.REACT_APP_CLS_BASE_API_URL;
export const MY_EDU_API_URL = process.env.REACT_APP_MY_EDUCATION_BASE_API_URL;
export const DEFAULT_LOCALE = process.env.REACT_APP_DEFAULT_LOCALE;
export const FRONT_URL = process.env.REACT_APP_FRONT_URL;
export const CLS_URL = process.env.REACT_APP_CLS_FRONT_URL;
export const MY_EDU_URL = process.env.REACT_APP_MY_EDUCATION_FRONT_URL;
export const BO_URL = process.env.REACT_APP_BO_URL;
export const WEP_API_BASE_URL = process.env.REACT_APP_WEP_API_BASE_URL;
export const WEP_API_GET_PRODUCTS_YEAR_URL = process.env.REACT_APP_WEP_API_GET_PRODUCTS_YEAR_URL
export const WEP_API_AUTHORIZATION_TOKEN = process.env.REACT_APP_WEP_API_AUTHORIZATION_TOKEN
export const WEP_API_GET_PRODUCTS_URL = process.env.REACT_APP_WEP_API_GET_PRODUCTS_URL

export const paramHeaders = {
  'Access-Control-Allow-Origin' : '*',
  'Content-Type': 'application/json',
  'Access-Control-Allow-Methods' : 'POST, PUT, GET, DELETE, OPTIONS',
  'Access-Control-Allow-Headers' : 'Origin, X-Requested-With, Content-Type, Accept',
};

export const paramHeadersPatch = {
  'Access-Control-Allow-Origin' : '*',
  'Content-Type': 'application/merge-patch+json',
  'Access-Control-Allow-Methods' : 'POST, PUT, GET, DELETE, PATCH, OPTIONS',
  'Access-Control-Allow-Headers' : 'Origin, X-Requested-With, Content-Type, Accept',
  'Accept-Patch' : 'application/merge-patch+json',
};

interface DomainConfig {
  hostnameType: string; // The type of the hostname, e.g., "mywep" or "cls"
  hostname: string;     // The base URL of the domain
  logo: {
    light: string;      // Path to the light version of the logo
    dark: string;       // Path to the dark version of the logo
  };
  title: string;        // The title to display for the domain
  favicon: string;      // Path to the favicon
  apiUrl: string;       // Base API URL for the domain
}

interface DomainConfigMap {
  [key: string]: DomainConfig; // Map of domain configurations (e.g., "default", "cls")
}

// Domain Configuration Object
export const DOMAIN_CONFIG: DomainConfigMap = {
  default: {
    hostnameType: "mywep",
    hostname: FRONT_URL ?? "",
    logo: {
      light: "../assets/images/logo/logo.png",
      dark: "../assets/images/logo/dark-logo.png",
    },
    title: "My WEP",
    favicon: "../assets/images/favicon.ico",
    apiUrl: BASE_API_URL ?? "",
  },
  cls: {
    hostnameType: "cls",
    hostname: CLS_URL ?? "",
    logo: {
      light: "../assets/images/cls/logo-cls.png",
      dark: "../assets/images/cls/logo-cls-white.png",
    },
    title: "My CLS",
    favicon: `${CLS_URL}/assets/images/cls/favicon.ico`,
    apiUrl: CLS_BASE_API_URL ?? "",
  },
  myedu: {
    hostnameType: "myedu",
    hostname: MY_EDU_URL ?? "",
    logo: {
      light: "../assets/images/myedu/MyEducation-logo.png",
      dark: "../assets/images/myedu/MyEducation-logo.png",
    },
    title: "My Edu",
    favicon: `${MY_EDU_URL}/assets/images/myedu/favicon.ico`,
    apiUrl: MY_EDU_API_URL ?? "",
  }
};